<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div class="app-content-box bg-gray">
      <div class="wrapper" v-if="isLoaded">
        <div class="container">
            <div class="text-center top-heading-small mt-4">
              aktualnie
            </div>
            <div class="text-center top-heading-medium">
              czytane książki
            </div>
        </div>
      <div class="slider-wrapper vh-60">
        <div class="growing-cover-slider">
          <UserProfileSlider :props="books" :componentId="id" :hideAdd=true />
         <router-link :to="{name: 'Authors', params: {id: bookData.autor_id}}"  class="text-dark">
          <div class="profile-book-name text-center mt-3">
            <div class="book-heading-small">{{bookData.autor}}</div>
          </div>
         </router-link>
          <router-link :to="{name: 'BooksDetails', params: {id: bookData.id}}" class="text-dark">
            <div class="book-heading-big text-center">
              {{bookData.tytul}}
            </div>
          </router-link>
            <div class="book-heading-medium mt-3 text-center">
              <i class="fas fa-book"></i> pozostało {{bookData.liczba_stron - bookData.przeczytane_strony}} stron
            </div>
            <div class="book-heading-medium mt-2 text-center">
             <i class="fas fa-book"></i> czytana {{bookData.przeczytane_strony}} strona
            </div>
        </div>
      </div>
      <div class="container">
     <!--   <div class="status-icon-row-summary row mt-5">
          <div class="col-4 pr-0">
            <div class="d-inline-block text-left">
              <i class="fas fa-book"></i> {{bookData.pagesLeft}}<br>
              pozostało stron
            </div>
          </div>
          <div class="col-4 pl-0 pr-0 text-center">
            <div class="d-inline-block text-left">
              <i class="fas fa-book"></i> {{bookData.pagesAll}}<br>
              czytana strona
            </div>
          </div>
          <div class="col-4 pl-0 text-right">
            <div class="d-inline-block text-left">
              <i class="far fa-clock"></i> {{bookData.hoursLeft}} H<br>
              do końca
            </div>
          </div>
        </div> -->
        <div class="input-container mt-5">
          <label class="label-small d-block text-center" for=""><strong>jak długo mniej więcej czytałeś?</strong></label>
        </div>
        <div class="form-checkbox mt-3 mb-3">
          <input type="checkbox" name="checkbox_1" id="checkbox_1" v-model="isChecked">
          <label for="checkbox_1">
            skończyłem czytać tę książkę
          </label>
        </div>
         <hr class="input-section-spacer">
          <div class="input-container">
            <input type="number" class="form-input w-100" placeholder="Na której stronie skończyłeś czytać?" v-model="pagesAmount" ref="pagesAmountRef">
          </div>
        <div class="input-spinner mt-4">
          <div class="top-heading-small text-center">Jak długo mniej więcej czytałeś?</div>
          <div class="input-spinners normal">
            <div class="input-spinner-wrapper input-spinner-time">
              <input type="hidden" name="input-minute">
                <VerticalSlider :options="sessionHoursArray" @active-index="setSessionHours"/>
              <div class="input-spinner-label">h</div>
            </div>
            <div class="input-spinner-wrapper input-spinner-wrapper-single input-spinner-timespan">
              <input type="hidden" name="input-minute">
              <VerticalSlider :options="sessionMinutesArray" @active-index="setSessionMinutes"/>
                <div class="input-spinner-label">min.</div>
            </div>
          </div>
        </div>
          <button class="btn-black-full w-100 mt-3 mb-1" @click="saveSession">Zaktualizuj</button>
        <router-link to="/plan-treningowy">
          <button class="btn-gray-border w-100 mt-1 mb-4">Anuluj</button>
        </router-link>
      </div>
    </div>
    <div v-else><Loader /></div>
    </div>
  </div>
</template>
<script>
import EventBus from '../eventBus'
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import VerticalSlider from '../components/VerticalSlider'
import UserProfileSlider from '../components/UserProfileSlider'
import Loader from '../components/Loader'
export default {
  name: 'TrainingUpdateProgress',
  components: {
    TopMenuSubheadline,
    VerticalSlider,
    UserProfileSlider,
    Loader
  },
  props: {
    id: String,
    date: String
  },
  data () {
    return {
      title: 'Aktualnie czytam',
      isLoaded: false,
      isChecked: false,
      pagesAmount: '',
      selectedDay: null,
      lastPage: '',
      isBookFinished: false,
      pagesLeft: '',
      pagesAll: '',
      hoursLeft: '',
      currentAuthor: '',
      currentTitle: '',
      isFinished: '',
      books: [],
      bookData: { autor: '', tytul: '', autor_id: 0, id: 0, rating: 0, liczba_stron: 0, przeczytane_strony: 0 },
      sessionHoursArray: ['00', '01', '02', '03', '04', '05'],
      sessionMinutesArray: ['00', '10', '20', '30', '40', '50'],
      activeSessionHours: 0,
      activeSessionMinutes: 0
    }
  },
  watch: {
    isChecked: function () {
      if (this.isChecked === true) {
        this.pagesAmount = this.bookData.liczba_stron
      } else {
        this.pagesAmount = ''
      }
    },
    bookData: function () {
      this.isChecked = false
    }
  },
  methods: {
    setSessionHours (index) {
      this.activeSessionHours = this.sessionHoursArray[index]
    },
    setSessionMinutes (index) {
      this.activeSessionMinutes = this.sessionMinutesArray[index]
    },
    updateData (payload) {
      this.bookData = payload
    },
    saveSession () {
      const sessionHours = this.activeSessionHours === 0 ? 0 : parseInt(this.activeSessionHours.substring(1))
      const sessionMin = this.activeSessionMinutes === 0 ? 0 : parseInt(this.activeSessionMinutes)
      const readingTime = (sessionHours * 60) + sessionMin
      this.pagesAmount = this.pagesAmount === '' ? 0 : this.pagesAmount
      this.isChecked = this.isChecked === true ? 1 : 0
      const getData = `date=${this.date}&time=${readingTime}&readed=${this.isChecked}&pages_readed=${this.pagesAmount}`
      this.$https('plan/' + this.id + '/books/' + this.bookData.id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.$router.push('/plan-treningowy')
      })
        .catch(error => {
          console.log('', error)
        })
    }
  },
  created () {
    this.$https(`/plan/${this.id}/books`, {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(({ data }) => {
        const item = data.response
        item.forEach(e => {
          if (e.objetosc - e.pages_readed !== 0) {
            this.books.push(e)
          }
        })
        this.isLoaded = true
      })

    EventBus.$on('books-in-' + this.id, (payload) => {
      this.updateData(payload)
    })
  },
  destroyed () {
    EventBus.$off('books-in-' + this.id, (payload) => {
      this.updateData(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .wrapper {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .form-input {
    font-size: 12px;
    &:active, &:focus {
      font-size: 12px;
    }
  }
  .input-section-spacer {
    border-top: 2px solid #000;
    margin: 0px 0px 25px 0px;
  }
</style>
