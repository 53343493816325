<template>
  <swiper :options="swiperOption" ref="mySwiper"
      @slideChange="swiperIndex"
      class="input-spinner-slider">
    <swiper-slide class="swiper-slide" v-for='(el, key) in options' :key="key" :data-value="el">
      {{el}}
    </swiper-slide>
  </swiper>
</template>
<script>
export default {
  name: 'VerticalSlider',
  props: {
    options: Array,
    time: Number
  },
  data () {
    return {
      swiperOption: {
        grabCursor: true,
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 10,
        mousewheel: true,
        observer: true
      },
      index: ''
    }
  },
  methods: {
    swiperIndex () {
      this.index = this.$refs.mySwiper.$swiper.activeIndex
      this.$emit('active-index', this.index)
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    if (this.time) {
      this.swiper.slideTo(this.time, 500, true)
    }
    this.id = this._uid
  }
}
</script>
<style lang="scss" scoped>

</style>
